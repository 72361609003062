<template>
  <header>
    <div class="row">
      <input id="search-input" class="serach-input" ref="filterInput" type="text" placeholder="Search roles [Name, Description]" v-model="searchString" :disabled="cmptFilteredRoleList.length <= 0 && !searchString" @keydown.esc="clearSearchString()" >
      <button class="btn-round btn-green" @click="addEditRole(null, 'add')" ><SystemIcons :type="'plusIcon'" /> </button>
    </div>
    <button @click="cycleSort" class="btn-sort">
      <span class="bold-text">Sort:</span>
      <p> <span v-if="sort == 0"> None </span> <span v-else-if="sort == 1"> Ascending </span> <span v-else-if="sort == -1"> Descending </span></p>
      <SystemIcons v-if="sort != 0" :type="'dropdownChevronDown'" class="dropdown-chevron" :class="{ 'chevron-up' : sort == -1}"/>
    </button>
  </header>

  <PlaceholderListElement
    v-if="cmptFilteredRoleList.length <= 0 && !searchString"
    :count="3"
    :listType="'role'"
  />
  <table v-else cellspacing="0" class="admin-table editable">
    <tr>
      <th>Name</th>
      <th>Description</th>
      <th>Company</th>

      <th>Admin Rights</th>
      <th v-if="$store.getters.getWipElementsState">Editor Rights</th>
      <th v-if="$store.getters.getWipElementsState">Publisher Rights</th>
      <th>Fabrix Rights</th>

      <!-- <th>Status</th> -->
    </tr>

    <tr v-for="(item, index) in cmptFilteredRoleList" :key="index" @click="addEditRole(item, 'edit')" class="admin-list-element">
      <td> <span class="lable-role" :class="[item.name]" :style="getRoleColor(item.name)" >{{item.name}}</span> </td>
      <td> <span > {{item.description}} </span></td>
      <td> <span > {{item.creatorCompany}} </span></td>

      <td> <input type="checkbox" :checked="calculatedUserRights(item).hasGeneralRights" disabled ></td>
      <td v-if="$store.getters.getWipElementsState"> <input type="checkbox" :checked="calculatedUserRights(item).hasEditorRights" disabled></td>
      <td v-if="$store.getters.getWipElementsState"> <input type="checkbox" :checked="calculatedUserRights(item).hasPublisherRights" disabled></td>
      <td> <input type="checkbox" :checked="calculatedUserRights(item).hasU3mRights" disabled></td>

      <!-- <td class="align-right"><button class="btn-standard btn-gray" @click.stop="toggleActive(item)"> {{item.active == true ? 'Deactivate' : 'Activate' }} </button></td> -->
    </tr>
  </table>
</template>

<script>
import { GLOBAL_MODAL_CONTENT } from '@/customCode/CustomObjects.js'
// import { UserRoleViewModel } from '@/customCode/AdministrationViewModels/AdminViewModels.js'
import SystemIcons from '@/components/elements/SystemIcons.vue'
import PlaceholderListElement from '@/components/elements/PlaceholderListElement.vue'

import { displayAuthMixins } from '@/mixins/DisplayAuthMixins.js'

export default {
  name: 'AdminManageRolesPanel',

  components: {
    SystemIcons,
    PlaceholderListElement
  },

  data () {
    return {
      searchString: '',
      sort: 0
    }
  },

  mixins:[displayAuthMixins],

  computed: {
    cmptFilteredRoleList () {
      if(!this.allowManageRoles())
      return []

      let data = this.$store.getters.getUserRoles

      var filterdData = data.filter((data) => {
        let predName = ((data.name != null) && (data.name.length > 0 ) && (data.name.toLowerCase().includes(this.searchString.toLowerCase())))
        let predDescription = ((data.description != null) && (data.description.length > 0 ) && (data.description.toLowerCase().includes(this.searchString.toLowerCase())))

        return predName || predDescription
      })
      if (this.sort != 0) {
        return filterdData.sort((a,b) => {
          if (this.sort == 1) {
            return a.name.localeCompare(b.name)
          } else if (this.sort == -1) {
            return b.name.localeCompare(a.name)
          }
        })
      } else {
        return filterdData
      }
    },
  },

  methods: {
    calculatedUserRights(userRole){
      const hasGeneralRights = Object.values(userRole.generalRights).reduce((total, value) => value || total);
      const hasEditorRights = Object.values(userRole.editorRights).reduce((total, value) => value || total);
      const hasPublisherRights = Object.values(userRole.publisherRights).reduce((total, value) => value || total);
      const hasU3mRights = Object.values(userRole.u3mRights).reduce((total, value) => value || total);

      return { hasGeneralRights, hasEditorRights, hasPublisherRights, hasU3mRights }
    },

    addEditRole (payload, modalMode) {
      this.$store.dispatch('showGlobalModal', { type: GLOBAL_MODAL_CONTENT.CREATE_UPDATE_ROLE, modalMode: modalMode, data: payload} )
    },

    clearSearchString () {
      this.searchString = ''
    },

    cycleSort () {
      if (this.sort == 0) {
        this.sort = 1
      } else if (this.sort == 1) {
        this.sort = -1
      } else if (this.sort == -1) {
        this.sort = 0
      }
    },

    toggleActive (payload) {
      console.log('toggleActive ', payload);
    },

    getRoleColor(stringInput) {
      let stringUniqueHash = [...stringInput].reduce((acc, char) => {
          return char.charCodeAt(0) + ((acc << 5) - acc);
      }, 0);
      let hsl = `hsl(${stringUniqueHash % 360}, 95%, 75%)`
      return `background-color: ${hsl}`;
    }
  },

  mounted() {
    this._keyListener = function(e) {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault();
        this.$refs.filterInput.focus()
      }
    };

    document.addEventListener('keydown', this._keyListener.bind(this));

    if(this.$store.getters.getUserRoles.length == 0)
      this.$store.dispatch('fetchAllUserRoles')

    if(this.$store.getters.getAllCompanys.length == 0)
      this.$store.dispatch('fetchAllAzureCompanies')
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this._keyListener);
  }
}
</script>

<style scoped lang="scss">
header {
  .serach-input {
    @include flex(auto, 1, 1);
    margin-right: .5rem;
  }
}

.title {
  font-weight: 800;
}

table {
  max-width: 100%;
}

th {
  min-width: 50px; /* for firefox */
  text-align: left;
}

.lable-role {
  font-weight: 800;
  padding: .2rem .75rem .30rem .75rem;
  border-radius: .25rem;
  white-space: nowrap;
}
</style>