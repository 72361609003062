<template>
  <header>
    <div class="row">
      <input id="search-input" class="serach-input" ref="filterInput" type="text" placeholder="Search licenses [Type, Name, Descritprion]" v-model="searchString" :disabled="cmptLicensesList.length <= 0 && !searchString" @keydown.esc="clearSearchString()" >
      <button v-if="cmptEnableLicenseAdding" class="btn-round btn-green" @click="addEditLicense(null, 'add')" ><SystemIcons :type="'plusIcon'" /> </button>
    </div>

    <div class="row">
      <button @click="cycleSort" class="btn-sort">
        <span class="bold-text">Sort:</span>
        <p> <span v-if="sort == 0"> None </span> <span v-else-if="sort == 1"> Ascending </span> <span v-else-if="sort == -1"> Descending </span></p>
        <SystemIcons v-if="sort != 0" :type="'dropdownChevronDown'" class="dropdown-chevron" :class="{ 'chevron-up' : sort == -1}"/>
      </button>

      <div class="spread"></div>

      <p v-if="$store.getters.getWipElementsState" class="label">Filter</p>
      <select v-if="$store.getters.getWipElementsState" v-model="licenseType">
        <option value="ALL">ALL</option>
        <option value="EDITOR">Editor</option>
        <option value="PUBLISHER">Publisher</option>
        <option value="U3M">U3M</option>
      </select>
    </div>
  </header>

  <PlaceholderListElement
    v-if="cmptLicensesList.length <= 0 && !searchString"
    :count="3"
    :listType="'license'"
  />
  <table v-else cellspacing="0" class="admin-table editable">
    <tr>
      <th>Type</th>
      <th>Name</th>
      <th>Descritprion</th>
      <th>Company</th>
      <th>Max Users</th>
      <th>Start Date</th>
      <th>End Date</th>

      <!-- <th>Views Per Month</th> -->
    </tr>
    <tr v-for="(item, index) in cmptLicensesList" :key="index" @click="addEditLicense(item, 'edit')" class="admin-list-element">
      <td class="title"> <SystemIcons :type="cmptLicensesIcon(item.category)" /> {{item.category}} </td>
      <td> {{item.name}} </td>
      <td> {{item.description}} </td>
      <td> {{item.creatorCompany}} </td>
      <td> {{item.maxUsers}} </td>
      <td> {{item.startDate}} </td>
      <td> {{item.endDate}} </td>

      <!-- <td class="align-right"><button class="btn-standard btn-gray" @click.stop="deleteLicense(item)">Delete</button></td> -->
    </tr>
  </table>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'
import { GLOBAL_MODAL_CONTENT } from '@/customCode/CustomObjects.js'
import { EditorLicense, PublisherLicense,  U3MViewerLicense} from '@/customCode/AdministrationViewModels/LicenseViewModels.js'
import PlaceholderListElement from '@/components/elements/PlaceholderListElement.vue'

import { displayAuthMixins } from '@/mixins/DisplayAuthMixins.js'

export default {
  name: 'AdminManageLicensesPanel',

  components: {
    SystemIcons,
    PlaceholderListElement
  },

  data () {
    return {
      searchString: '',
      licenseType: "ALL",
      sort: 0
    }
  },

  mixins:[displayAuthMixins],

  computed: {
    cmptLicensesList () {
      if(!this.allowManageLicenses())
      return []

      let data = this.$store.getters.getLicenseList

      if(this.licenseType != "ALL")
        data = data.filter(d => ((d.category == this.licenseType)))


      var filterdData = data.filter((data) => {
        let predName = ((data.name != null) && (data.name.length > 0 ) && (data.name.toLowerCase().includes(this.searchString.toLowerCase())))
        let predCategory = ((data.category != null) && (data.category.length > 0 ) && (data.category.toLowerCase().includes(this.searchString.toLowerCase())))
        let predDescription = ((data.description != null) && (data.description.length > 0 ) && (data.description.toLowerCase().includes(this.searchString.toLowerCase())))

        return predName || predCategory || predDescription
      })
      if (this.sort != 0) {
        return filterdData.sort((a,b) => {
          if (this.sort == 1) {
            return a.type.localeCompare(b.type)
          } else if (this.sort == -1) {
            return b.type.localeCompare(a.type)
          }
        })
      } else {
        return filterdData
      }
    },

    cmptEnableLicenseAdding(){
      return (this.allowEditorLicensing () || this.allowPublisherLicensing () || this.allowU3MLicensing ())
    }
  },

  methods: {
    addEditLicense (payload, action) {
      let displayLicenseType = (action != 'add')? this.licenseType : null
      let modalMode = {action: action, displayLicenseType: displayLicenseType}
      this.$store.dispatch('showGlobalModal', { type: GLOBAL_MODAL_CONTENT.CREATE_UPDATE_LICENSE, modalMode: modalMode, data: payload } )
    },

    clearSearchString () {
      this.searchString = ''
    },

    cycleSort () {
      if (this.sort == 0) {
        this.sort = 1
      } else if (this.sort == 1) {
        this.sort = -1
      } else if (this.sort == -1) {
        this.sort = 0
      }
    },

    async deleteLicense (lic) {
      let result = await this.$store.dispatch('deleteLicense', lic)
      console.log("Delete License, this is what i got ", result);
      if(!result.suceeded)
        alert("Cannot delete. License is in use")
    },

    cmptLicensesIcon (type) {
      switch (type) {
        case 'U3M':
          return 'uTreeMIcon'
        case 'EDITOR':
          return 'cube'
        case 'PUBLISHER':
          return 'backChevron'
        default:
          break;
      }
    }
  },

  mounted() {
    this._keyListener = function(e) {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault();
        this.$refs.filterInput.focus()
      }
    };

    document.addEventListener('keydown', this._keyListener.bind(this));

    if(this.$store.getters.getLicenseList.length == 0){
      this.$store.dispatch('fetchAllLicensesForCompany')
    }

    if(this.$store.getters.getAllCompanys.length == 0)
      this.$store.dispatch('fetchAllAzureCompanies')
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this._keyListener);
  }
}
</script>

<style scoped lang="scss">
.title {
  font-weight: 800;
  @include flexbox;
  @include align-items(center);

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: .5rem;
  }
}

select {
  height: 1.5rem;
  padding: 0 .5rem;
}

.label {
  margin-right: .5rem;
}

header {
  .serach-input {
    @include flex(auto, 1, 1);
    margin-right: .5rem;
  }
}
</style>