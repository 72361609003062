<template>
  <ul class="loading-placeholder">
    <li v-for="index in count" :key="index">
      <div class="icon-placeholder">
        <SystemIcons v-if="listType" :type="listType"/>
      </div>
      <div class="title-placeholder"></div>
      <div class="spread"></div>
      <div class="button-placeholder"></div>
      <p :style="{animationDelay: index / 100 + 's'}"></p>
    </li>
  </ul>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'

export default {
  name: 'PlaceholderListElement',

  components: {
    SystemIcons
  },

  props: {
    count: Number,
    listType: String
  },

  data () {
    return {}
  },

  computed: {},
  methods: {}
}
</script>

<style scoped lang="scss">

.loading-placeholder {
  width: 100%;
  margin: 3rem 0 0 0;
  padding: 0;

  li {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5rem;
    margin-bottom: 1rem;
    background-color: var(--bg-tertiary);
    border-radius: 1rem;
    padding: 1rem;

    @include flexbox;
    @include align-items(center);

    .icon-placeholder, .title-placeholder, .button-placeholder {
      background-color: var(--bg-primary);
      border-radius: .25rem;
    }

    .icon-placeholder {
      height: 100%;
      width: 5rem;
      margin-right: 2rem;

      @include flexbox;
      @include align-items(center);
      @include flex-just(center);

      svg {
        width: 1.25rem;
        height: 1.25rem;
        opacity: .25;
      }
    }

    .title-placeholder {
      height: 1.5rem;
      width: 15rem;
      border-radius: .75rem;
    }

    .spread {
      @include flex(auto, 1, 1);
    }

    .button-placeholder {
      height: 2.25rem;
      width: 5rem;
      border-radius: 1.125rem;
    }

    p {
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 15rem;
      background: linear-gradient(to right, transparent 0%, var(--bg-primary) 50%, transparent 100%);
      -moz-animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
      -webkit-animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
      animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
  }
}

@keyframes load {
  from {
    left: -15rem;
  }
  to   {
    left: 100%;
  }
}

</style>