<template>
  <div class="admin-container">
    <h1 v-if="cmptShowCompanies && !cmptShowUsers && !cmptShowLicenses && !cmptShowRoles">Manage Companies</h1>
    <h1 v-else-if="cmptShowUsers && !cmptShowCompanies && !cmptShowLicenses && !cmptShowRoles">Manage User</h1>
    <h1 v-else-if="cmptShowLicenses && !cmptShowUsers && !cmptShowCompanies && !cmptShowRoles">Manage Licenses</h1>
    <h1 v-else-if="cmptShowRoles && !cmptShowUsers && !cmptShowLicenses && !cmptShowCompanies">Manage Roles</h1>
    <div v-else-if="!cmptShowCompanies && !cmptShowUsers && !cmptShowLicenses && !cmptShowRoles" class="no-admin-info-block">
      <h1>You do not have administrator rights</h1>
      <router-link to="/" class="btn-back"><SystemIcons :type="'backChevron'"/><p>Material overview</p></router-link>
    </div>
    <h1 v-else>Admin Panel</h1>

    <fieldset v-if="cmptShowCompanies || cmptShowUsers || cmptShowLicenses || cmptShowRoles" class="radio-btn" @change="saveAdminTabMode()">
      <input v-if="cmptShowCompanies" type="radio" id="companies" value="companies" v-model="adminTabMode" />
      <label v-if="cmptShowCompanies" for="companies"> <SystemIcons :type="'company'" class="tab-icon"/> Companies </label>
      <input v-if="cmptShowUsers" type="radio" id="user" value="user" v-model="adminTabMode" />
      <label v-if="cmptShowUsers" for="user"> <SystemIcons :type="'userIcon'" class="tab-icon"/> User </label>
      <input v-if="cmptShowLicenses" type="radio" id="licenses" value="licenses" v-model="adminTabMode" />
      <label v-if="cmptShowLicenses" for="licenses"> <SystemIcons :type="'license'" class="tab-icon"/> Licenses </label>
      <input v-if="cmptShowRoles" type="radio" id="roles" value="roles" v-model="adminTabMode" />
      <label v-if="cmptShowRoles" for="roles"> <SystemIcons :type="'role'" class="tab-icon"/> Roles </label>
    </fieldset>


    <component
      v-if="cmptShowCompanies || cmptShowUsers || cmptShowLicenses || cmptShowRoles"
      :is="cmptGetCurrentTabComponent"
    />

  </div>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'

import AdminManageCompaniesPanel from '@/panels/AdminManageCompaniesPanel.vue'
import AdminManageUserPanel from '@/panels/AdminManageUserPanel.vue'
import AdminManageLicensesPanel from '@/panels/AdminManageLicensesPanel.vue'
import AdminManageRolesPanel from '@/panels/AdminManageRolesPanel.vue'

import { displayAuthMixins } from '@/mixins/DisplayAuthMixins.js'

export default {
  name: 'AdminPanel',

  components: {
    SystemIcons,
    AdminManageCompaniesPanel,
    AdminManageLicensesPanel,
    AdminManageUserPanel,
    AdminManageRolesPanel
  },

  data () {
    return {
      adminTabMode: 'companies'
    }
  },

  mixins:[displayAuthMixins],

  computed: {
    cmptShowCompanies () {
      return this.allowManageCompanies()
    },
    cmptShowUsers () {
      return this.allowManageUser()
    },
    cmptShowLicenses () {
      return this.allowManageLicenses()
    },
    cmptShowRoles () {
      // return true
      return this.allowManageRoles()
    },
    cmptGetCurrentTabComponent(){
      switch (this.adminTabMode) {
        case 'companies':
          return AdminManageCompaniesPanel
        case 'user':
          return AdminManageUserPanel
        case 'licenses':
          return AdminManageLicensesPanel
        case 'roles':
          return AdminManageRolesPanel
        default:
          break;
      }
    }
  },

  methods: {
    saveAdminTabMode (payload) {
      this.$store.dispatch('setSuperAdminTab', this.adminTabMode);
    }
  },

  created() {
    this.adminTabMode = this.$store.getters.getSuperAdminTab
  },

  mounted() {},
  beforeUnmount() {}
}
</script>

<style scoped lang="scss">
.admin-container {
  text-align: center;
  max-width: $max-width-small * 2;
  padding: 2.5rem 0 5rem 0;
  margin: 0 auto;
  text-align: left;

  .no-admin-info-block {
    text-align: center;
    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);
  }

  h1 {
    margin-bottom: 3rem;
    text-align: center;
    color: var(--accent-primary);
    // color: var(--accent-secondary);
    // color: var(--accent-tertiary);
  }

  .radio-btn {
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 5rem;

    .tab-icon {
      display: inline-block;
      margin-right: .25rem;
      transform: scale(1.25) translateY(.1rem);
      width: 1rem;
      height: 1rem;
    }

    label {
      min-width: 10rem;
      text-align: center;
    }
  }
}
</style>