<template>
  <header>
    <div class="row">
      <input id="search-input" class="serach-input" ref="filterInput" type="text" placeholder="Search user [Name, Company, Email]" v-model="searchString" :disabled="cmptFilteredUsersList.length <= 0 && !searchString" @keydown.esc="clearSearchString()" >
      <button class="btn-round btn-green" @click="addEditUser('Add New', 'add')" ><SystemIcons :type="'plusIcon'" /> </button>
    </div>
    <button @click="cycleSort" class="btn-sort">
      <span class="bold-text">Sort:</span>
      <p> <span v-if="sort == 0"> None </span> <span v-else-if="sort == 1"> Ascending </span> <span v-else-if="sort == -1"> Descending </span></p>
      <SystemIcons v-if="sort != 0" :type="'dropdownChevronDown'" class="dropdown-chevron" :class="{ 'chevron-up' : sort == -1}"/>
    </button>
  </header>

  <PlaceholderListElement
    v-if="cmptFilteredUsersList.length <= 0 && !searchString"
    :count="3"
    :listType="'userIcon'"
  />
  <table v-else cellspacing="0" class="admin-table editable">
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Company</th>
      <th>Role</th>
      <th class="align-right">Reset PW</th>

      <!-- <th class="align-right">Status</th> -->
    </tr>

    <tr v-for="(item, index) in cmptFilteredUsersList" :key="index" @click="addEditUser(item, 'edit')" class="admin-list-element">
      <td><p class="title"> <SystemIcons :type="'userIcon'"/> <span>{{item.givenName}} {{item.surname}}</span> </p> </td>
      <td> {{item.eMail}} </td>
      <td> {{item.companyName}} </td>
      <td> <span :class="['lable-role', {'has-no-role' : getRoleName(item.role) == 'no role' }]" :style="getRoleName(item.role) != 'no role' ? getRoleColor(getRoleName(item.role)) : ''"> {{getRoleName(item.role)}} </span> </td>
      <td class="align-right"><button class="btn-standard btn-gray" @click.stop="resetPassword(item)"> Reset Password </button></td>

      <!-- <td class="align-right"><button class="btn-standard btn-gray" @click.stop="toggleActive(item)"> {{item.active == true ? 'Deactivate' : 'Activate' }} </button></td> -->
    </tr>
  </table>
</template>

<script>
import { GLOBAL_MODAL_CONTENT } from '@/customCode/CustomObjects.js'
import SystemIcons from '@/components/elements/SystemIcons.vue'
import PlaceholderListElement from '@/components/elements/PlaceholderListElement.vue'

import { displayAuthMixins } from '@/mixins/DisplayAuthMixins.js'

export default {
  name: 'AdminManageUserPanel',

  components: {
    SystemIcons,
    PlaceholderListElement
  },

  data () {
    return {
      searchString: '',
      sort: 0
    }
  },

  mixins:[displayAuthMixins],

  computed: {
    cmptFilteredUsersList () {

      if(!this.allowManageUser())
      return []

      let data = this.$store.getters.getCompanyUsers

      var filterdData = data.filter((data) => {
        let predGivenName = ((data.givenName != null) && (data.givenName.length > 0 ) && (data.givenName.toLowerCase().includes(this.searchString.toLowerCase())))
        let predSurname = ((data.surname != null) && (data.surname.length > 0 ) && (data.surname.toLowerCase().includes(this.searchString.toLowerCase())))
        let predCompany = ((data.companyName != null) && (data.companyName.length > 0 ) && (data.companyName.toLowerCase().includes(this.searchString.toLowerCase())))
        let predEmail =  ((data.eMail != null) && (data.eMail.length > 0 ) && (data.eMail.toLowerCase().includes(this.searchString.toLowerCase())))
        // let predRole = data.role.toLowerCase().includes(this.searchString.toLowerCase())

        return predGivenName || predSurname || predCompany || predEmail
      })
      if (this.sort != 0) {
        return filterdData.sort((a,b) => {
          if (this.sort == 1) {
            return ((a.givenName != null) && (b.givenName != null))? a.givenName.localeCompare(b.givenName) : 0
          } else if (this.sort == -1) {
            return ((a.givenName != null) && (b.givenName != null))? b.givenName.localeCompare(a.givenName) : 0
          }
        })
      } else {
        return filterdData
      }
    },
  },

  methods: {
    getRoleName(roleId){
      let userrole = this.$store.getters.getUserRoles.find(r => r.id == roleId)
      return userrole? userrole.name : "no role"
    },
    addEditUser (payload, modalMode) {
      this.$store.dispatch('showGlobalModal', { type: GLOBAL_MODAL_CONTENT.CREATE_UPDATE_USER, modalMode: modalMode, data: payload } )
    },

    clearSearchString () {
      this.searchString = ''
    },

    cycleSort () {
      if (this.sort == 0) {
        this.sort = 1
      } else if (this.sort == 1) {
        this.sort = -1
      } else if (this.sort == -1) {
        this.sort = 0
      }
    },

    toggleActive (payload) {
      console.log('toggleActive ', payload);
    },

    async resetPassword (payload) {
      let text = "Are you sure you want to reset the users password?";
      if (confirm(text) == false)
      return;

      // console.log('resetPassword ', payload);
      // console.log('resetPassword ID', payload.id);
      let result = await this.$store.dispatch('resetUserPWD', payload.id)

      let messageObj = {user: payload.eMail, newPWD: result.newPWD}
      this.displayPWDMessage(messageObj)



    },
    async displayPWDMessage (messageObj) {
      let message = `The password for user '${messageObj.user}'has been changed.\nThe new password is: ${messageObj.newPWD}\nThe password will not be shown again, after closing this window`
      // let message =  `The password for user`  //'{}' has been changed./nThe new password is: {messageObj.newPWD}´
      // console.log("displayPWDMessage messageObj ", messageObj);
      // console.log("displayPWDMessage message ", message);
      this.$store.dispatch('showGlobalModal', { type: GLOBAL_MODAL_CONTENT.DISPLAY_USERINFO, data: {message: message } } )
    },
    getRoleColor(stringInput) {
      let stringUniqueHash = [...stringInput].reduce((acc, char) => {
          return char.charCodeAt(0) + ((acc << 5) - acc);
      }, 0);
      let hsl = `hsl(${stringUniqueHash % 360}, 95%, 75%)`
      return `background-color: ${hsl}`;
    }
  },

  async mounted() {
    this._keyListener = function(e) {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault();
        this.$refs.filterInput.focus()
      }
    };

    document.addEventListener('keydown', this._keyListener.bind(this));
    if(this.$store.getters.getCompanyUsers.length == 0)
      this.$store.dispatch('fetchAllCompanyUsers')

    if(this.$store.getters.getUserRoles.length == 0)
      this.$store.dispatch('fetchAllUserRoles')

    if(this.$store.getters.getAllCompanys.length == 0)
      this.$store.dispatch('fetchAllAzureCompanies')
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this._keyListener);
  }
}
</script>

<style scoped lang="scss">
header {
  .serach-input {
    @include flex(auto, 1, 1);
    margin-right: .5rem;
  }
}

.title {
  font-weight: 800;

  @include flexbox;
  @include align-items(center);

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
  }
}

.lable-role {
  font-weight: 800;
  padding: .2rem .75rem .30rem .75rem;
  border-radius: .25rem;

  &.has-no-role {
    padding: 0;
    opacity: .5;
    font-weight: 400;
    font-style: italic;
  }
}
</style>