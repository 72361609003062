<template>
  <header>
    <div class="row">
      <input id="search-input" class="serach-input" ref="filterInput" type="text" placeholder="Search companies [Name, Description]" v-model="searchString" :disabled="cmptFilteredCompanyList.length <= 0 && !searchString" @keydown.esc="clearSearchString()" >
      <button class="btn-round btn-green" @click="addEditCompany('Add New', 'add')" ><SystemIcons :type="'plusIcon'" /> </button>
    </div>
    <button @click="cycleSort" class="btn-sort">
      <span class="bold-text">Sort:</span>
      <p> <span v-if="sort == 0"> None </span> <span v-else-if="sort == 1"> Ascending </span> <span v-else-if="sort == -1"> Descending </span></p>
      <SystemIcons v-if="sort != 0" :type="'dropdownChevronDown'" class="dropdown-chevron" :class="{ 'chevron-up' : sort == -1}"/>
    </button>
  </header>

  <PlaceholderListElement
    v-if="cmptFilteredCompanyList.length <= 0 && !searchString"
    :count="3"
    :listType="'company'"
  />
  <table v-else cellspacing="0" class="admin-table editable">
    <tr>
      <th></th>
      <th>Name</th>
      <!-- <th>Email</th> -->
      <th>Description</th>
      <!-- <th>Website</th> -->
      <th v-if="$store.getters.getWipElementsState">Editor License</th>
      <th v-if="$store.getters.getWipElementsState">Publisher License</th>
      <th>Fabrix License</th>
      <th class="align-right">Users</th>
      <!-- <th class="align-right">Impressions</th> -->
      <!-- <th class="align-right">Status</th> -->
    </tr>
    <tr v-for="(item, index) in cmptFilteredCompanyList" :key="index" @click.stop="addEditCompany(item, 'edit')" class="admin-list-element">
      <td> <div class="company-logo"> <SystemIcons :type="'company'" /> </div> </td>
      <td class="title"> {{item.name}} </td>
      <!-- <td> {{item.eMail}} </td> -->
      <td> {{item.info}} </td>
      <!-- <td> {{item.website}} </td> -->
      <td v-if="$store.getters.getWipElementsState" :class="{'has-no-license' : getEditorLicenseName(item.editorLicenseId) == 'no license' }"> <p>{{getEditorLicenseName(item.editorLicenseId)}}</p> </td>
      <td v-if="$store.getters.getWipElementsState" :class="{'has-no-license' : getPublisherLicenseName(item.publisherLicenseId) == 'no license' }"> <p>{{getPublisherLicenseName(item.publisherLicenseId)}}</p> </td>
      <td :class="{'has-no-license' : getU3MEditorLicenseName(item.u3MViewerLicenseId) == 'no license' }"> <p>{{getU3MEditorLicenseName(item.u3MViewerLicenseId)}}</p> </td>
      <td class="align-right"> {{item.userCount}} </td>
      <!-- <td class="align-right"> {{item.impressions.toLocaleString()}} </td> -->
      <!-- <td class="align-right"><button class="btn-standard btn-gray" @click.stop="toggleActive(item)"> {{item.active == true ? 'Deactivate' : 'Activate' }} </button></td> -->
      <!-- <td class="align-right"><button class="btn-standard btn-gray" @click.stop="deleteCompany(item)">Delete</button></td> -->
    </tr>
  </table>
</template>

<script>
import { GLOBAL_MODAL_CONTENT } from '@/customCode/CustomObjects.js'
import SystemIcons from '@/components/elements/SystemIcons.vue'
import PlaceholderListElement from '@/components/elements/PlaceholderListElement.vue'

import { displayAuthMixins } from '@/mixins/DisplayAuthMixins.js'

export default {
  name: 'AdminManageCompaniesPanel',

  components: {
    SystemIcons,
    PlaceholderListElement
  },

  data () {
    return {
      searchString: '',
      sort: 0
    }
  },

  mixins:[displayAuthMixins],

  computed: {
    cmptFilteredCompanyList () {
      if(!this.allowManageCompanies())
      return []

      let data = this.$store.getters.getAllCompanys

      var filterdData = data.filter((data) => {
        let predName = ((data.name != null) && (data.name.length > 0 ) && (data.name.toLowerCase().includes(this.searchString.toLowerCase())))
        let predInfo = ((data.info != null) && (data.info.length > 0 ) && (data.info.toLowerCase().includes(this.searchString.toLowerCase())))
        let predLicense = ((data.license != null) && (data.license.length > 0 ) && (data.license.toLowerCase().includes(this.searchString.toLowerCase())))

        return predName || predInfo || predLicense
      })

      if (this.sort != 0) {
        return filterdData.sort((a,b) => {
          if (this.sort == 1) {
            return a.name.localeCompare(b.name)
          } else if (this.sort == -1) {
            return b.name.localeCompare(a.name)
          }
        })
      } else {
        return filterdData
      }
    }
  },

  methods: {
    getEditorLicenseName(licenseId){
      let lic = this.$store.getters.getLicenseList.find(d => ((d.category == "EDITOR") && (d.id == licenseId)))
      console.log("getMEditorLicenseName .... " , lic);
      return (lic != null)? lic.name : "no license"
    },
    getPublisherLicenseName(licenseId){
      let lic = this.$store.getters.getLicenseList.find(d => ((d.category == "PUBLISHER") && (d.id == licenseId)))
      return (lic != null)? lic.name : "no license"
    },
    getU3MEditorLicenseName(licenseId){
      let lic = this.$store.getters.getLicenseList.find(d => ((d.category == "U3M") && (d.id == licenseId)))
      return (lic != null)? lic.name : "no license"
    },


    addEditCompany (payload, modalMode) {
      this.$store.dispatch('showGlobalModal', { type: GLOBAL_MODAL_CONTENT.CREATE_UPDATE_COMPANY, modalMode: modalMode, data: payload } )
    },

    async deleteCompany (lic) {
      // let result = await this.$store.dispatch('deleteLicense', lic)
      // console.log("Delete License, this is what i got ", result);
      // if(!result.suceeded)
      alert("Cannot delete Company. Remove the users first")
    },

    clearSearchString () {
      this.searchString = ''
    },

    cycleSort () {
      if (this.sort == 0) {
        this.sort = 1
      } else if (this.sort == 1) {
        this.sort = -1
      } else if (this.sort == -1) {
        this.sort = 0
      }
    },

    toggleActive (payload) {
      console.log('toggleActive ', payload);
    }
  },

  mounted() {
    this._keyListener = function(e) {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault();
        this.$refs.filterInput.focus()
      }
    };

    document.addEventListener('keydown', this._keyListener.bind(this));

    if(this.$store.getters.getAllCompanys.length == 0)
      this.$store.dispatch('fetchAllAzureCompanies')

    if(this.$store.getters.getLicenseList.length == 0)
      this.$store.dispatch('fetchAllLicensesForCompany')

  },

  beforeUnmount() {
    document.removeEventListener('keydown', this._keyListener);
  }
}
</script>

<style scoped lang="scss">

.title {
  font-weight: 800;
}

.company-logo {
  width: 5rem;
  height: 3rem;
  background-color: var(--bg-primary);
  border-radius: .25rem;

  @include flexbox;
  @include align-items(center);
  @include flex-just(center);

  svg {
    width: 1.25rem;
    height: 1.25rem;
    opacity: .25;
  }
}

.has-no-license p {
  // padding: 0;
  font-weight: 400;
  font-style: italic;
  opacity: .4;
}

header {
  // .row {
  //   @include flexbox;
  //   @include align-items(center);
  // }

  .serach-input {
    @include flex(auto, 1, 1);
    margin-right: .5rem;
  }
}
</style>